import type { OrientationLockOptions } from '@capacitor/screen-orientation'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { onCleanup, onMount } from 'solid-js'

export const useFullscreen = (element: HTMLElement) => {
  onMount(() => {
    element?.requestFullscreen({ navigationUI: 'hide' }).catch(console.error)
  })
  onCleanup(async () => {
    document.exitFullscreen?.().catch(console.error)
  })
}

export const useOrientationLock = (
  orientation: OrientationLockOptions['orientation'],
) => {
  onMount(() => {
    ScreenOrientation.lock?.({ orientation }).catch(console.error)
  })

  onCleanup(() => {
    try {
      ScreenOrientation.unlock()
    } catch {
      // ignore
    }
  })
}
