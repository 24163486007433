import { onCleanup, onMount } from 'solid-js'

export const useOverscrollBehavior = (value: string) => {
  onMount(() => {
    const prevValue = document.body.style.overscrollBehavior
    if (prevValue !== 'contain') {
      document.body.style.overscrollBehavior = value
      const { parentElement } = document.body

      if (parentElement) {
        parentElement.style.overscrollBehavior = value
      }

      onCleanup(() => {
        document.body.style.overscrollBehavior = prevValue

        if (parentElement) {
          parentElement.style.overscrollBehavior = prevValue
        }
      })
    }
  })
}
